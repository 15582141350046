@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$purple: #7f00f9;
$purple-dark: #78125b;
$yellow: #fff55d;
$pink: #fb41b9;
$pink-light: #fad1ef;
$beige-light: #d0d1cd;
$beige-lighter: #fcfcfc;

// // Update Bulma's global variables
// $family-sans-serif: 'Nunito', sans-serif;
// $grey-dark: $brown;
// $grey-light: $beige-light;
$primary: $pink;
// $link: $pink;
// $widescreen-enabled: false;
// $fullhd-enabled: false;


$secondary: #8BC91D;
$secondary-invert: findColorInvert($secondary) !default;

$custom-colors: (
    "secondary":($secondary, $secondary-invert),
    "outlined": ($secondary, white)
);

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$navbar-item-img-max-height: 3rem;
$navbar-height: 3rem;
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;

// Import only what you need from Bulma
@import "../node_modules/bulma/";
// @import "../node_modules/bulma/sass/utilities/_all.sass";
// @import "../node_modules/bulma/sass/base/_all.sass";
// @import "../node_modules/bulma/sass/elements/button.sass";
// @import "../node_modules/bulma/sass/elements/container.sass";
// @import "../node_modules/bulma/sass/elements/title.sass";
// @import "../node_modules/bulma/sass/form/_all.sass";
// @import "../node_modules/bulma/sass/components/navbar.sass";
// @import "../node_modules/bulma/sass/layout/hero.sass";
// @import "../node_modules/bulma/sass/layout/section.sass";


//Components
@import "./pages/Home.scss";
@import "./components/Person.scss";
@import "./components/JudgingCriteria.scss";
