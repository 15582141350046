
$yellow: #fff55d;
$pink: #fb41b9;

.Home {
  .welcome-container {
    padding-top: 5rem;
    padding-right: 1.5rem;
    padding-bottom: 0;
    padding-left: 1.5rem;
  }
  .welcome-title {
    background-image: linear-gradient($yellow, $yellow);
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: 100% 2px;
    transition: background-size .5s, background-position .3s ease-in .5s;
    font-weight: bold;
    margin-bottom: 10px;

    &:hover {
      transition: background-position .5s, background-size .3s ease-in .5s;
      background-size: 100% 100%;
      background-position: 0% 0%;
    }

    @include from($tablet) {
      width: 50%;
    }  
  }

  .teaser-text {
    padding-right: 30px;
  }

  .teaser-image {
    padding: 5%;
  }

  .button {
    margin: 15px 15px 0px 0px;
  }

  .alignPartnerImgs {
      text-align: center;
      display: flex;
      align-items: center;
  }

  @include until($tablet) {
    .reverse-columns {
      flex-direction: column-reverse;
      display: flex;
    }
  }  
}
